<template>
<div>
  <van-cell-group>
    <van-cell :title="$t('消息推送')" >
      <template #right-icon>
        <van-switch @change="updatePushSwitch" v-model="pushSwitch" size="24" />
      </template>
    </van-cell>
    <van-cell to="/user/password/modify" :title="$t('修改密码')" is-link />
  </van-cell-group>
</div>
</template>

<script>
import {Button, Col, Switch, Row, CellGroup, SidebarItem, Skeleton, Cell} from "vant";
import {getUserInfo, updateUserInfo} from "@/api/user"
import {readCommonParam} from "../../../util/session-storage-utils";
export default {
  name: "index",
  components:{
    [CellGroup.name]:CellGroup,
    [Cell.name]:Cell,
    [SidebarItem.name]:SidebarItem,
    [Row.name]:Row,
    [Col.name]:Col,
    [Button.name]:Button,
    [Switch.name]:Switch,
    [Skeleton.name]:Skeleton,
  },
  data(){
    return {
      pushSwitch:false
    }
  },
  created() {
    readCommonParam(this)
    getUserInfo().then((res) => {
      this.pushSwitch = res.data.pushSwitch
    })
  },
  methods:{
    updatePushSwitch(value){
      updateUserInfo({pushSwitch:value})
      this.pushSwitch = value
    }
  }
}
</script>

<style scoped>

</style>
